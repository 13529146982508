<template>
    <div class="">
        <div class="panel">
            <!-- <legend class="mt-0">{{$t('settings.users.inviteUsers')}}</legend> -->
            <legend class="mt-0">{{$t('settings.integrations.webhooks.heading')}}</legend>
            <p class="max-w-xl">
                {{$t('settings.integrations.webhooks.text')}}
                <!-- <a href="https://stripe.com/docs/webhooks">webhooks</a>. <a @click="webhookNew.url = 'https://api.mycompany.com/webhooks'">paste</a> -->
            </p>
            
            
            <form @submit.prevent="onWebhookAdd">
                <InputSingleline 
                    v-model="webhookNew.url"
                    type="url"
                    :label="$t('settings.integrations.webhooks.newWebhookEndpoint')"
                    :required="true"
                    placeholder="https://api.mycompany.com/webhooks"
                />
                <button class="button submit">
                    {{$t('settings.integrations.webhooks.addWebhook')}}
                </button>
            </form>
        </div>
        
        <div 
            v-if="accountEdit.integrations.webhooks && accountEdit.integrations.webhooks.length > 0"
            class="panel mt-12">
            
            <legend class="mt-0">
                {{$t('settings.integrations.webhooks.allWebhooks')}}
            </legend>
            
            <div class="mt-8 ">
                <a 
                    v-for="(webhook,wI) in accountEdit.integrations.webhooks"
                    :key="'webhook_'+wI"
                    class="p-4 border border-gray-200 hover:bg-gray-100 flex items-start" 
                    style="margin-top: -1px;"
                    @click="onWebhookEdit(webhook)">
                    
                    <div class="flex-none w-20 h-20 mr-6 flex items-center justify-center bg-gray-300 text-white rounded">
                        <i class="fas text-4xl fa-network-wired" />
                    </div>
                    
                    <div class="flex-grow md:flex items-center">
                        <div class="flex-grow mr-8">
                            <div class="text-black w-48 truncate md:w-auto">{{webhook.url}}</div>
                            <div>{{webhook.createdAt | moment('lll')}}</div>
                        </div>
                        <div class="flex-none mr-8 capitalize">
                            <div 
                                v-if="webhook.status == 'disabled'"
                                class="bg-red-500 text-white py-1 px-3 rounded-full inline-block">
                                
                                {{$t('general.disabled')}}
                            </div>
                            <div 
                                v-else-if="webhook.status == 'enabled'"
                                class="bg-green-500 text-white py-1 px-3 rounded-full inline-block">
                                
                                {{$t('general.enabled')}}
                            </div>
                        </div>
                        
                        <div class="flex-none mr-8">{{$tc('settings.integrations.webhooks.eventsCount', webhook.subscriptions.length)}}</div>
                        <div class="flex-none mr-8 link">{{$t('general.edit')}}</div>
                    </div>
                </a>

                
                <Modal 
                    v-if="webhookEdit"
                    @close="webhookEdit = null"
                    width="">
                    
                    <header slot="header">
                        <div class="py-4 px-6">
                            {{$t('settings.integrations.webhooks.editWebhook')}}
                        </div>
                    </header>
                    
                    <form 
                        class="pb-6 px-6 overflow-auto h-100 md:h-auto w-100 md:w-200"
                        @submit.prevent="onWebhookDone()">
                        
                        <InputSingleline 
                            v-model="webhookEdit.url"
                            :required="true"
                            label="Webhook Endpoint"
                        />
                        
                        <div class="md:grid grid-cols-3 gap-8">
                            <div class="">
                                <label for="">{{$t('general.created')}}</label>
                                <input type="text" readonly :value="webhookEdit.createdAt | moment('lll')">
                            </div>
                            <div class="">
                                <label for="">{{$t('general.updated')}}</label>
                                <input type="text" readonly :value="webhookEdit.updatedAt || webhookEdit.createdAt | moment('lll')">
                            </div>
                            <div class="">
                                <label for="">{{$t('general.status')}}</label>
                                <select v-model="webhookEdit.status">
                                    <option value="disabled">{{$t('general.disabled')}}</option>
                                    <option value="enabled">{{$t('general.enabled')}}</option>
                                </select>
                            </div>
                        </div>
                        
                        <label for="">{{$t('general.description')}}</label>
                        <textarea 
                            v-model="webhookEdit.description"
                            class="h-24"
                            :placeholder="$t('settings.integrations.webhooks.descriptionPlaceholder')"
                        />
                        
                        <label for="">{{$t('settings.integrations.webhooks.eventsToSend')}}</label>
                        <div class="md:grid grid-cols-3 gap-8 mb-8 py-2 px-4 border">
                            <div 
                                v-for="(section,index) in subscriptionSections"
                                :key="'section_'+index"
                                class="">
                                
                                <!-- <div class="my-2 opacity-50">{{section.name}}</div> -->
                                
                                <label 
                                    v-for="subscription in section.subscriptions"
                                    :key="subscription"
                                    class="my-1 block md:inline-block w-auto "
                                    @change="onSubscriptionChange(subscription)">
                                    
                                    <input 
                                        class="mr-1"
                                        type="checkbox"
                                        :checked="webhookEdit.subscriptions.find( s => s == subscription)"
                                    />
                                    
                                    {{subscription}}
                                </label>
                            </div>
                        </div>
                    </form>
                    
                    <footer slot="footer">
                        <div class="flex">
                            <button
                                :disabled="loading"
                                class="button transparent flex-none"
                                type="button"
                                @click.prevent="onWebhookDelete(webhookEdit.webhookId)">
                                
                                <span class="">{{$t('general.delete')}}</span>
                            </button>
                            
                            
                            <div class="flex-grow flex justify-end">
                                
                                <a 
                                    class="button transparent link" 
                                    @click="webhookEdit = null">
                                    
                                    {{ $t('general.cancel') }}
                                </a>
                                
                                <button 
                                    type="submit"
                                    class="button submit"
                                    @click.prevent="onWebhookDone()">
                                    
                                    {{ $t('general.done') }}
                                </button>
                            </div>
                        </div>
                    </footer>
                </Modal>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        props: {
            accountEdit: {
                type: Object,
                required: true,
            }
        },
        
        data() {
            return {
                loading: null,
                webhookEdit: null,
                webhookNew: {
                    webhookId: null,
                    url: null,
                    createdAt: null,
                    updatedAt: null,
                    description: null,
                    status: 'disabled',
                    subscriptions: [],
                },
                subscriptionSections: [
                    {
                        name: 'Products',
                        subscriptions: [
                            'product.created',
                            'product.updated',
                            'product.deleted',
                        ],
                    },
                    {
                        name: 'Offers',
                        subscriptions: [
                            'offer.created',
                            'offer.updated',
                            'offer.deleted',
                        ],
                    },
                    {
                        name: 'Customers',
                        subscriptions: [
                            'customer.created',
                            'customer.updated',
                            'customer.deleted',
                        ],
                    },
                ],
            }
        },
        
        methods: {
            onWebhookAdd(){
                this.webhookNew.createdAt = new Date().getTime();
                this.webhookNew.webhookId = 'webhook_'+(Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
                this.accountEdit.integrations.webhooks.push( this.webhookNew );
                this.onWebhookEdit( this.webhookNew );
                this.webhookNew.url = '';
                this.$notify({ type: 'success', text: this.$t('settings.integrations.webhooks.addedWebhook') });
            },
            
            onSubscriptionChange( subscription) {
                const exists = this.webhookEdit.subscriptions.find( s => s == subscription);
                
                if ( !exists ) {
                    this.webhookEdit.subscriptions.push( subscription );
                }
                else {
                    this.webhookEdit.subscriptions = this.webhookEdit.subscriptions.filter( s => s != subscription);
                }
            },
            
            onWebhookEdit( webhook ){
                this.webhookEdit = JSON.parse( JSON.stringify( webhook ) );
            },
            
            onWebhookDone(){
                let webhookToUpdate = this.accountEdit.integrations.webhooks.find( webhook => webhook.webhookId == this.webhookEdit.webhookId );
                webhookToUpdate.url = this.webhookEdit.url;
                webhookToUpdate.status = this.webhookEdit.status;
                webhookToUpdate.description = this.webhookEdit.description;
                webhookToUpdate.subscriptions = this.webhookEdit.subscriptions;
                webhookToUpdate.updatedAt = new Date().getTime();
                this.webhookEdit = null;
            },
            
            onWebhookDelete( webhookId ){
                this.accountEdit.integrations.webhooks = this.accountEdit.integrations.webhooks.filter( webhook => webhook.webhookId != webhookId );
                this.webhookEdit = null;
            },
        },
    }
</script>
